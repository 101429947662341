import React, { Component } from 'react'
import styled from 'styled-components'
import { Box, Title3, Large, colors, Button } from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { Helmet } from 'react-helmet'
import { PageTitle } from '../../components/PageTitle'
import Default from '../../components/Layouts/Default';

import Megaphone from '../images/megaphone.svg'
import Dials from '../images/dials.svg'
import G2Crowd from '../images/g2Crowd.svg'

export default class CustomerSuccess extends Component {
  constructor(props) {
    super(props)
    this.animationIsAttached = false
  }

  componentDidMount() {
    this.attachAnimation()
  }

  attachAnimation() {
    if (this.animationContainer !== undefined && !this.animationIsAttached) {
      // const animationProperties = {
      //   container: this.animationContainer,
      //   renderer: 'svg',
      //   loop: true,
      //   autoplay: true,
      //   animationData: animationData,
      // }

      // lottie.loadAnimation(animationProperties)
    }
  }

  render() {
    return (
      <Default>
        <Helmet>
          <title>Truly | Customer Success Practice</title>
        </Helmet>

        <WrapperBackground
          color={colors.trulyDark}
          background='successPractice'
          gradientType='darkMoreTransparent'
        >
          <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width={[0, 1/2]}>
            </Box>
            <Box width={[1, 2/3]} pr={['0pt', '32pt']}>
              <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem">
              PARTNERSHIPS OVER PRODUCTS
              </Title3>
              <PageTitle as="h1" color={colors.white}>
              YOU DESERVE A SUPPORT TEAM THAT WILL GO THE EXTRA MILE
              </PageTitle>
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperBackground
          color={colors.trulyDark}
          background='telephony1'
          gradientType='light'
        >
          <Section flexDirection="column" my={['1rem', '2rem', '5rem']}>
            <Box width="3/4" pb="2rem">
              <WidthImg src={Megaphone} alt="Megaphone"/>
            </Box>
            <Box width={[1, 3/4]}>
              <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
              COMMUNICATION IS ONLY AS GOOD AS THE TECHNOLOGY IT IS BUILT ON
              </Title3>
              <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
              Every implementation is unique. They are as unique as each of our customers with unique business challenges and superlative standards. Choosing a phone system is a great responsibility – and we feel the same responsibility towards our customers. After all, striving for customer succss requires meaningful partnership and perfect service.
              </Large>
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperBackground
          color={colors.trulyDark}
          background='government'
        >
          <Section flexDirection={['column', 'row']} py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width="1/2" pb="32pt">
              <CenteredImg src={Dials} alt="Dials"/>
            </Box>
            <Box width={[1, 1/2]} pl={['0pt', '64pt']}>
              <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem">
              WE WORK, EVEN WHEN NOTHING ELSE HAS BEFORE
              </Title3>
              <Large as="p" color={colors.white} mb="1.5rem">
                In addition to a unique product, customers can expect unique service that is individually tailored to their personal requirements. That is why Truly is the partner of choice for heavily regulated businesses, from healthcare to government.
              </Large>
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperSolid>
          <Section flexDirection="column" my={['1rem', '2rem', '5rem']}>
            <Box width={[1, 3/4]}>
              <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
              EXCEED EXPECTATIONS
              </Title3>
              <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
              Our customers are our inspiration. We work in lockstep to provide individual trainings, design custom workflows, and define the future product roadmap. We live with our customers to ensure six star levels of service and success.
              </Large>
            </Box>
            <Box width="3/4" pb="2rem">
              <WidthImg src={G2Crowd} alt="G2Crowd"/>
            </Box>
            <a href="https://www.g2crowd.com/products/truly/reviews">
              <Button size="extraLarge" type="outline" round value="Read What Our Customers Say About Us"></Button>
            </a>
          </Section>
        </WrapperSolid>

      </Default>
    )
  }
}

const CenteredImg = styled.img`
  display: block;
  margin: 0 auto;
`

const WidthImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`
